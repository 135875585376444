import React, { useState, useEffect } from 'react';
import './Home.css';
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useHistory } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import logo from 'assets/images/forgedfitnessLogo.png';
import CoverLayout from "layouts/webpages/components/membership_list/BasicLayout";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import { motion } from 'framer-motion';
import { FaUsers, FaCreditCard, FaPersonBooth, FaCashRegister } from 'react-icons/fa';

const images = [
  { original: require('assets/images/products/create-account.png'), thumbnail: require('assets/images/products/create-account.png') },
  { original: require('assets/images/products/search.png'), thumbnail: require('assets/images/products/search.png') },
  { original: require('assets/images/products/home.png'), thumbnail: require('assets/images/products/home.png') },
  { original: require('assets/images/products/dash.png'), thumbnail: require('assets/images/products/dash.png') },
  { original: require('assets/images/products/IMG_8001.png'), thumbnail: require('assets/images/products/IMG_8001.png') },
  { original: require('assets/images/products/IMG_8002.png'), thumbnail: require('assets/images/products/IMG_8002.png') },
  { original: require('assets/images/products/IMG_8004.png'), thumbnail: require('assets/images/products/IMG_8004.png') },
];

const App = () => {
  return (
    <div className="App">
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
};

const getUserToken = () => {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
  for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'userToken') {
          return value;
      }
  }
  return null; // Return null if userToken cookie is not found
};

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [gymlogo, setLogo] = useState(null);

  const getInfo = async (token) => {
    const searchEndpoint = 'https://www.phynux.tech/api/forged-fitness/getGymInfo';
    try {
      const response = await fetch(searchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      setLogo(data.logo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const validateToken = async (login_token) => {
    try {
      const endpoint = 'https://www.phynux.tech/api/forged-fitness/gym-protected';
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login_token }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Server response:', data);

      if (data.status === 'Token validated') {
        getInfo(login_token);
      }
    } catch (error) {
      console.error('Error during token validation:', error);
    }
  };

  useEffect(() => {
    const token = getUserToken();
    if (token) {
      validateToken(token);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <motion.header
      className="App-header"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="logo" style={{ marginTop: '10px' }}>
        <motion.img
          src={logo}
          alt="Logo"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        />
      </div>
      <nav>
        <ul className={`menu ${menuOpen ? 'open' : ''}`} style={{ listStyle: 'none', padding: 0 }}>
          <motion.li whileHover={{ scale: 1.1 }} style={{ height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href="#features" onClick={toggleMenu}>Features</a>
          </motion.li>
          <motion.li whileHover={{ scale: 1.1 }} style={{ height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href="#pricing" onClick={toggleMenu}>Pricing</a>
          </motion.li>
          <motion.li whileHover={{ scale: 1.1 }} style={{ height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href="#about" onClick={toggleMenu}>About</a>
          </motion.li>
          <motion.li whileHover={{ scale: 1.1 }} style={{ height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href="#contact" onClick={toggleMenu}>Contact</a>
          </motion.li>
          <motion.li whileHover={{ scale: 1.1 }} style={{ height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <a href="#gallery" onClick={toggleMenu}>Gallery</a>
          </motion.li>
          <motion.li
            whileHover={{ scale: 1.1 }}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}
          >
            {gymlogo ? (
              <DefaultNavbarLink
                icon={gymlogo}
                name="dashboard"
                route="/dashboard"
                style={{ display: 'flex', alignItems: 'center' }}
              />
            ) : (
              <DefaultNavbarLink
                icon="key"
                name="sign in"
                route="/authentication/sign-in"
                style={{ display: 'flex', alignItems: 'center' }}
              />
            )}
          </motion.li>
        </ul>
      </nav>
      <motion.button
        className="menu-toggle"
        onClick={toggleMenu}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        &#9776;
      </motion.button>
    </motion.header>
  );
};


const MainContent = () => {

  return (
    <PageLayout>
      <main>
        <CoverLayout
          title="Forge Ahead with Forged-Fitness Gym Management Software"
          color="white"
          description="Empower your gym with technology that scales with your ambition."
          premotto="SECURE, RELIABLE, QUALITY:"
          motto="FORGED-FITNESS"
          image={logo}
        />

        <section id="features" className="features">
          <h2>Features</h2>
          <div className="feature-list">
            <Feature
              title="Membership Management"
              description="Efficiently manage memberships, including sign-ups, renewals, and cancellations."
              icon={<FaUsers />}
            />
            <Feature
              title="Secure Payments"
              description="Leverage Stripe's powerful payment platform for secure, efficient subscription management and point-of-sale transactions."
              icon={<FaCreditCard />}
            />
            <Feature
              title="Active Crowd Metering"
              description="Our one-of-a-kind active crowd metering system provides up to 98% accuracy, ensuring real-time information about facility occupancy."
              icon={<FaPersonBooth />}
            />
            <Feature
              title="In-App Mobile POS"
              description="Our integrated mobile POS system allows users to purchase products and services directly within the app, offering a seamless and secure shopping experience."
              icon={<FaCashRegister />}
            />

          </div>
        </section>

        <section id="pricing" className="pricing">
          <h2>Pricing</h2>
          <div className="pricing-plans">
            <PricingPlan
              name="Basic"
              price="$140/month"
              features={["Free door hardware", "OTA Security Updates for Equipment", "Online and Mobile Membership Signups", "POS System", "400 Member Cap"]}
            />
            <PricingPlan
              name="Pro"
              price="$250/month"
              features={["All Basic features", "Unlimited members", "Priority support", "Active Crowd Metering"]}
            />
            <PricingPlan
              name="Enterprise"
              price="Contact Us"
              features={["All Pro features", "Multi-location support"]}
            />
          </div>
        </section>
        <section id="gallery" className="gallery">
          <h2>Photo Gallery</h2>
          <ImageGallery items={images} />
        </section>
        <section id="about" className="about">
          <h2>About Us</h2>
          <p>Forged Fitness is a cutting-edge gym management software that pushes the boundaries of what's possible in gym management. Developed by our parent company, Phynux, we integrate robust technology to provide a seamless and secure experience for gym owners and members alike. Our mission is to continually find innovative ways to enhance security and simplify operations, ensuring that managing a gym has never been easier. Join us on our journey to revolutionize gym management and experience the future of fitness technology today.</p>
        </section>
        <section id="contact" className="contact">
          <h2>Contact Us</h2>
          <ContactForm />
        </section>
      </main>
    </PageLayout>
  );
};

const Feature = ({ title, description, icon }) => {
  return (
    <motion.div
      className="feature"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="feature-icon">{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </motion.div>
  );
};

const PricingPlan = ({ name, price, features }) => {
  const history = useHistory();

  const handleChoosePlan = () => {
    history.push('/authentication/sign-up');
  };

  return (
    <motion.div
      className="pricing-plan"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <h3>{name}</h3>
      <p className="price">{price}</p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <motion.button
        className="cta-button"
        onClick={handleChoosePlan}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        Choose Plan
      </motion.button>
    </motion.div>
  );
};

const ContactForm = () => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const postMessage = async (name,email,message) => {
    const searchEndpoint = 'https://www.phynux.tech/submit';

    try {
      const response = await fetch(searchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });
      const data = await response.json();
      if(data.message){
        setIsSubmit(true)
        alert("Thank you for your message. We have received your inquiry and will respond within 24 hours. We appreciate your patience.")
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postMessage(formData.name, formData.email, formData.message)
    console.log('Form submitted:', formData);
  };

  return (
    <>
    {isSubmit ? (
      <p style={{textAlign: 'center', alignSelf: 'center'}}>Thank you for your message. We have received your inquiry and will respond within 24 hours. We appreciate your patience.</p>
    ):(
      <form className="contact-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Name</label>
        <input type="text" name="name" required onChange={handleChange} value={formData.name} />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input type="email" name="email" required onChange={handleChange} value={formData.email} />
      </div>
      <div className="form-group">
        <label>Message</label>
        <textarea name="message" required onChange={handleChange} value={formData.message}></textarea>
      </div>
      <motion.button
        type="submit"
        className="cta-button"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        disabled={isSubmit}
      >
        Send Message
      </motion.button>
    </form>
    )}
    </>
  );
};

const Footer = () => {
  return (
    <footer className="App-footer">
      <p>&copy; 2024 Phynux. All rights reserved.</p>
      {/*<div className="footer-links">
        <a href="/privacy">Privacy Policy</a>
        <a href="/terms">Terms of Service</a>
        <a href="/careers">Careers</a>
      </div>*/}
    </footer>
  );
};

export default App;